.new-expense {
  background-color: #edf6f9;
  padding: 1rem;
  margin: 2rem auto;
  width: 50rem;
  max-width: 95%;
  border-radius: 10px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  /* box-shadow: 0 1px 8px rgba(0, 0, 0, 0.25); */
}

.new-expense button {
  font: inherit;
  cursor: pointer;
  padding: 1rem 2rem;
  border: 1px solid #293241;
  background-color: #293241;
  color: white;
  border-radius: 12px;
  margin-right: 1rem;
}

.new-expense button:hover,
.new-expense button:active {
  background-color: #510674;
  border-color: #510674;
}

.new-expense button.alternative {
  color: #220131;
  border-color: transparent;
  background-color: transparent;
}

.new-expense button.alternative:hover,
.new-expense button.alternative:active {
  background-color: #ddb3f8;
}
